import styled from '@emotion/styled';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material';
import { Mapping } from '../../../../types/Mapping.types';
import { useMappings } from '../../../../hooks/api/useMappings';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useReloadSettings } from '../../../../hooks/api/useReloadSettings';
import { License } from '../../../../types/Licenses.types';
import { Payment } from '../../../../types/Payments.types';
import { PublishingModel } from '../../../../types/PublishingModels.types';
import DeleteMappingDialog from './DeleteMappingDialog';
import MappingDialog from './MappingDialog';

const StyledTable = styled(Table)`
  & tbody tr:hover {
    background-color: #f3f6f9;
    color: #0b313f;
  }
`;

const StyledButton = styled(Button)`
  &:focus {
    outline: 2px solid #0b313f;
  }
`;

const typeColors = {
  licenses: '#E6F4E6',
  payments: '#FFEDD5',
  publishing_models: '#DBEAFE',
  columns: '#FEF9C3',
};

const StyledTableBox = styled(Box)<{
  type: keyof typeof typeColors;
}>`
  && {
    color: #0b313f;
    font-weight: 600;
    height: 20px;
    text-align: center;
    text-transform: capitalize;
    white-space: nowrap;
    width: fit-content;
    padding: 0 10px 0 10px;
    border-radius: 0.5rem;
    background-color: ${(props) => typeColors[props.type]};
  }
`;

const StyledTableCell = styled(TableCell)`
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface MappingTableProps {
  label: string;
  licenses: License[];
  payments: Payment[];
  publishingModels: PublishingModel[];
}

const MappingTable = ({
  label,
  licenses,
  payments,
  publishingModels,
}: MappingTableProps) => {
  const { mappings, createMapping, deleteMapping, updateMapping } =
    useMappings();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState<{
    original: string;
    type: string;
    value: string;
  } | null>(null);

  const { reloadSettings } = useReloadSettings();

  const handleOpenCreate = () => {
    setEditData(null);
    setOpen(true);
  };

  const handleOpenEdit = (mapping: Mapping) => {
    setEditData({
      original: mapping.original,
      type: mapping.type,
      value: mapping.value,
    });
    setOpen(true);
  };

  const handleSubmit = async (data: {
    original: string;
    type: string;
    value: string;
  }) => {
    if (editData) {
      await updateMapping({
        id: mappings.find((mapping) => mapping.original === editData.original)
          ?._id!,
        ...data,
      });
    } else {
      await createMapping(data);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteMapping(id);
    } catch (error) {
      console.error('Error deleting mapping:', error);
    }
  };

  return (
    <Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end' }}
        mb={16}
        gap={16}
      >
        <StyledButton
          sx={{ display: 'none' }}
          variant="contained"
          color="primary"
          onClick={() => reloadSettings()}
        >
          Reload settings
        </StyledButton>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleOpenCreate}
        >
          {`Add new ${label}`}
        </StyledButton>
        <MappingDialog
          open={open}
          onClose={() => setOpen(false)}
          onSubmit={handleSubmit}
          initialData={editData ?? undefined}
          mode={editData ? 'edit' : 'create'}
          licenses={licenses}
          payments={payments}
          publishingModels={publishingModels}
        />
      </Box>
      <TableContainer component={Paper}>
        <Box sx={{ maxHeight: 480, overflowY: 'auto' }}>
          <StyledTable sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ borderBottom: '2px solid #E0E0E0' }}>
                <TableCell align="left">Normalized</TableCell>
                <TableCell align="left">Original</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Value</TableCell>
                <TableCell align="left">Created at</TableCell>
                <TableCell align="left">Updated at</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mappings.length > 0 ? (
                mappings.map((mapping: Mapping) => (
                  <TableRow key={mapping._id}>
                    <StyledTableCell align="left">
                      <Tooltip title={mapping.normalized} arrow>
                        <span>{mapping.normalized}</span>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Tooltip title={mapping.original} arrow>
                        <span>{mapping.original}</span>
                      </Tooltip>
                    </StyledTableCell>
                    <TableCell align="left">
                      <StyledTableBox type={mapping.type}>
                        {mapping.type.replace('_', ' ')}
                      </StyledTableBox>
                    </TableCell>
                    <StyledTableCell align="left">
                      <Tooltip title={mapping.value} arrow>
                        <span>{mapping.value}</span>
                      </Tooltip>
                    </StyledTableCell>
                    <TableCell align="left">
                      {dayjs(mapping.created_at.slice(0, -4)).format(
                        'DD-MM-YYYY'
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {mapping.updated_at
                        ? dayjs(mapping.updated_at.slice(0, -4)).format(
                            'DD-MM-YYYY'
                          )
                        : '-'}
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleOpenEdit(mapping)}>
                        Edit
                      </Button>

                      <DeleteMappingDialog
                        onClick={() => handleDelete(mapping._id)}
                        mappingName={mapping.original}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography>No {label}s available</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
        </Box>
      </TableContainer>
    </Box>
  );
};

export default MappingTable;

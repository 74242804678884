import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useState } from 'react';

export function useGetAccessToken() {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [token, setToken] = useState<string>('');

  const fetchToken = useCallback(async () => {
    try {
      if (token) {
        return token;
      }
      const accessToken = await getAccessTokenSilently();
      setToken(accessToken);
      return accessToken;
    } catch (error: any) {
      if (error.message?.includes('invalid_grant')) {
        await loginWithRedirect({
          appState: {
            targetUrl: process.env.REACT_APP_AUTH0_REDIRECT_URI,
          },
        });
      }
      return;
    }
  }, [getAccessTokenSilently, loginWithRedirect, token]);

  return { getToken: fetchToken };
}

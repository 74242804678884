import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../contexts/AuthContext';
import { useApi } from './useApi';
import { Mapping } from '../../types/Mapping.types';
import { queryClient } from './react-query';

export const useMappings = () => {
  const { account } = useAuthContext();
  const { makeApiRequest } = useApi();

  const { data: mappings = [] } = useQuery({
    queryKey: ['mappings', account],
    queryFn: async (): Promise<Mapping[]> => {
      const response = await makeApiRequest('/mappings', 'GET');

      if (response && typeof response === 'object') {
        return Object.values(response).flat() as Mapping[];
      }
      return [];
    },
    enabled: !!account,
  });

  const { mutateAsync: createMapping } = useMutation({
    mutationFn: ({
      original,
      type,
      value,
    }: {
      original: string;
      type: string;
      value: string;
    }) =>
      makeApiRequest('/mappings', 'POST', {
        original,
        type,
        value,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mappings', account] });
    },
  });

  const { mutateAsync: deleteMapping } = useMutation({
    mutationFn: async (id: string) => {
      return makeApiRequest(`/mappings/${id}`, 'DELETE');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mappings', account] });
    },
  });

  const { mutateAsync: updateMapping } = useMutation({
    mutationFn: async ({
      id,
      original,
      type,
      value,
    }: {
      id: string;
      original: string;
      type: string;
      value: string;
    }) => {
      return makeApiRequest(`/mappings/${id}`, 'PUT', {
        original,
        type,
        value,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mappings', account] });
    },
  });

  return {
    mappings,
    createMapping,
    deleteMapping,
    updateMapping,
  };
};

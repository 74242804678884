import styled from '@emotion/styled';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { ChangeEvent, useEffect, useState } from 'react';
import { useReloadSettings } from '../../../../hooks/api/useReloadSettings';
import { ApiValidationError, isApiError } from '../../../../types/Error';
import { resizeImage } from '../../../../utils/imageUtils';

const StyledTable = styled(Table)`
  & tbody tr:hover {
    background-color: #f3f6f9;
    color: #0b313f;
    cursor: pointer;
  }
`;

const StyledButton = styled(Button)`
  &:focus {
    outline: 2px solid #0b313f;
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: 20px;
`;

interface AdminTableProps {
  title: string;
  label: string;
  data?: {
    id: string;
    url?: string;
    description?: string;
    icon?: string;
    createdAt: string;
    createdBy: string;
  }[];
  onAddItem: (item: {
    id: string;
    url?: string;
    description?: string;
    icon?: string;
  }) => Promise<void>;
  isAdding: boolean;
}

const AdminTable = ({
  title,
  label,
  data = [],
  onAddItem,
  isAdding,
}: AdminTableProps) => {
  const [newItemName, setNewItemName] = useState('');
  const [newItemUrl, setNewItemUrl] = useState('');
  const [newItemDescription, setNewItemDescription] = useState('');
  const [newItemIcon, setNewItemIcon] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formErrors, setFormErrors] = useState<ApiValidationError | null>(null);
  const [open, setOpen] = useState(false);
  const { reloadSettings } = useReloadSettings();

  useEffect(() => {
    setFormErrors(null);
    setNewItemName('');
    setNewItemUrl('');
    setNewItemDescription('');
    setNewItemIcon('');
    setErrorMessage('');
  }, [open]);

  const handleAdd = async () => {
    setErrorMessage('');
    if (!newItemName.trim()) {
      setErrorMessage('Please enter a name');
      return;
    }

    if (
      data?.some((item) => item.id.toLowerCase() === newItemName.toLowerCase())
    ) {
      setErrorMessage(`This ${label} already exists`);
      return;
    }

    const newItem = {
      id: newItemName,
      ...(label === 'license' && { url: newItemUrl }),
      ...(label !== 'publisher' && {
        description: newItemDescription,
        icon: newItemIcon,
      }),
    };

    setFormErrors(null);

    try {
      await onAddItem(newItem);
      setOpen(false);
      setNewItemName('');
      setNewItemUrl('');
      setNewItemDescription('');
      setNewItemIcon('');
      setErrorMessage('');
    } catch (error) {
      console.error(`Error adding new ${label}:`, error);
      if (isApiError(error)) {
        setFormErrors(error);
      }
    }
  };

  const handleIconChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const resizedImage = await resizeImage(selectedFile);
      setNewItemIcon(resizedImage);
    }
  };

  return (
    <Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end' }}
        mb={16}
        gap={16}
      >
        <StyledButton
          sx={{ display: 'none' }}
          variant="contained"
          color="primary"
          onClick={() => reloadSettings()}
        >
          Reload settings
        </StyledButton>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={isAdding}
        >
          {isAdding ? 'Adding...' : `Add new ${label}`}
        </StyledButton>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{`Add new ${label}`}</DialogTitle>
        <DialogContent sx={{ width: 400 }}>
          <StyledTextField
            required
            margin="dense"
            label={`${label} name`}
            type="text"
            fullWidth
            onChange={(e) => setNewItemName(e.target.value)}
            error={!!errorMessage}
            helperText={errorMessage}
          />
          {label === 'license' && (
            <StyledTextField
              margin="dense"
              label="URL (must begin with 'https://')"
              type="text"
              fullWidth
              onChange={(e) => setNewItemUrl(e.target.value)}
              error={!!formErrors?.url}
              helperText={formErrors?.url?.[0]}
            />
          )}

          {label !== 'publisher' && (
            <>
              <StyledTextField
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                onChange={(e) => setNewItemDescription(e.target.value)}
                error={!!formErrors?.description}
                helperText={formErrors?.description?.[0]}
              />
              <Box
                display="flex"
                flexDirection="column"
                border="1px solid #C4C4C4"
                padding="10px"
                mb={16}
                mt={8}
              >
                <label htmlFor="logoImage">
                  Icon <b>(.png / .svg)</b>
                </label>
                <input
                  type="file"
                  accept=".png, .svg"
                  name="icon"
                  onChange={handleIconChange}
                />
                {formErrors?.icon && (
                  <FormHelperText error>{formErrors.icon[0]}</FormHelperText>
                )}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton
            onClick={() => {
              setOpen(false);
              setErrorMessage('');
            }}
            color="primary"
          >
            Cancel
          </StyledButton>
          <StyledButton onClick={handleAdd} color="primary">
            Add {label}
          </StyledButton>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Box sx={{ maxHeight: 480, overflowY: 'auto' }}>
          <StyledTable sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ borderBottom: '2px solid #E0E0E0' }}>
                <TableCell align="left">Name</TableCell>
                {label === 'license' && <TableCell align="left">URL</TableCell>}
                {label !== 'publisher' && (
                  <TableCell align="left">Description</TableCell>
                )}
                {label !== 'publisher' && (
                  <TableCell align="center">Icon</TableCell>
                )}
                <TableCell align="left">Created at</TableCell>
                <TableCell align="left">Created by</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.length > 0 ? (
                data.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell align="left">{item.id}</TableCell>
                    {label === 'license' && (
                      <TableCell align="left">
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.url}
                        </a>
                      </TableCell>
                    )}
                    {label !== 'publisher' && (
                      <TableCell align="left">{item.description}</TableCell>
                    )}
                    {label !== 'publisher' && (
                      <TableCell
                        align="center"
                        sx={{
                          width: 100,
                          minWidth: 100,
                          height: '60px !important',
                          padding: '8px',
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            height: '44px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden',
                          }}
                        >
                          <img
                            src={item.icon}
                            style={{
                              maxWidth: '100%',
                              maxHeight: '44px',
                              objectFit: 'contain',
                              display: 'block',
                            }}
                            alt="icon"
                          />
                        </div>
                      </TableCell>
                    )}
                    <TableCell align="left">
                      {dayjs(item.createdAt.slice(0, -4)).format(
                        'DD-MM-YYYY HH:mm'
                      )}
                    </TableCell>
                    <TableCell align="left">{item.createdBy}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography>No {label}s available</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
        </Box>
      </TableContainer>
    </Box>
  );
};

export default AdminTable;

import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import { ApiValidationError } from '../../../../types/Error';
import { License } from '../../../../types/Licenses.types';
import { Payment } from '../../../../types/Payments.types';
import { PublishingModel } from '../../../../types/PublishingModels.types';
import { useColumns } from '../../../../hooks/api/useColumns';

interface MappingDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: {
    original: string;
    type: string;
    value: string;
  }) => Promise<void>;
  initialData?: { original: string; type: string; value: string };
  mode: 'create' | 'edit';
  licenses: License[];
  payments: Payment[];
  publishingModels: PublishingModel[];
}

const initialState = { original: '', type: '', value: '' };

const MappingDialog = ({
  open,
  onClose,
  onSubmit,
  initialData = initialState,
  mode,
  licenses,
  payments,
  publishingModels,
}: MappingDialogProps) => {
  const { columns } = useColumns();
  const [formData, setFormData] = useState(initialState);
  const [formErrors, setFormErrors] = useState<ApiValidationError | null>(null);

  useEffect(() => {
    setFormData(initialData);
    setFormErrors(null);
  }, [open, initialData]);

  const getValueOptions = () => {
    switch (formData.type) {
      case 'licenses':
        return licenses.map((license) => ({ id: license._id }));
      case 'payments':
        return payments.map((payment) => ({ id: payment._id }));
      case 'publishing_models':
        return publishingModels.map((model) => ({ id: model._id }));
      case 'columns':
        return columns.map((column) => ({ id: column }));
      default:
        return [];
    }
  };

  const handleSubmit = async () => {
    try {
      await onSubmit(formData);
      onClose();
    } catch (error) {
      console.error('Error:', error);
      setFormErrors(error as ApiValidationError);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {mode === 'create' ? 'Add new mapping' : 'Edit mapping'}
      </DialogTitle>
      <DialogContent sx={{ width: 400 }}>
        <TextField
          required
          margin="dense"
          label="Original name to map"
          type="text"
          fullWidth
          value={formData.original}
          onChange={(e) =>
            setFormData({ ...formData, original: e.target.value })
          }
          error={!!formErrors?.original?.[0]}
        />
        <Typography variant="caption" color="error">
          {formErrors?.original?.[0]}
        </Typography>

        <FormControl fullWidth sx={{ mt: 16 }} variant="outlined">
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            required
            value={formData.type}
            label="Type"
            labelId="type-label"
            displayEmpty
            onChange={(e) =>
              setFormData({
                ...formData,
                type: e.target.value,
                value: '',
              })
            }
            error={!!formErrors?.type?.[0]}
          >
            {['licenses', 'payments', 'publishing_models', 'columns'].map(
              (type) => (
                <MenuItem key={type} value={type}>
                  {type.replace('_', ' ')}
                </MenuItem>
              )
            )}
          </Select>
          <Typography variant="caption" color="error">
            {formErrors?.type?.[0]}
          </Typography>
        </FormControl>

        {formData.type && (
          <FormControl fullWidth sx={{ mt: 16 }} variant="outlined">
            <InputLabel id="value-label">Value</InputLabel>
            <Select
              required
              labelId="value-label"
              value={formData.value}
              onChange={(e) =>
                setFormData({ ...formData, value: e.target.value })
              }
              label="Value"
              displayEmpty
              error={!!formErrors?.value?.[0]}
            >
              {getValueOptions().map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.id}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="caption" color="error">
              {formErrors?.value?.[0]}
            </Typography>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {mode === 'create' ? 'Add' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MappingDialog;

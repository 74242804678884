import styled from '@emotion/styled';
import { Box, Button, Paper } from '@mui/material';
import { SettingsTabType } from './types';

const StyledTabBtn = styled(Button)`
  border-radius: 0;

  &:focus {
    background: rgba(0, 0, 0, 0.04);
  }

  &:hover {
  }
`;

const activeTabStyles = {
  borderBottom: '3px solid #177d8e',
  background: 'rgba(0, 0, 0, 0.04)',
};

type TabSelectProps = {
  onTabSelect: (tab: SettingsTabType) => void;
  tab: SettingsTabType;
};

const buttons: { tabType: SettingsTabType; label: string }[] = [
  { tabType: 'general', label: 'General' },
  { tabType: 'university', label: 'University' },
];

export const SettingsTabSelect = ({ onTabSelect, tab }: TabSelectProps) => {
  return (
    <Box
      component={Paper}
      sx={{
        marginTop: 12,
      }}
    >
      {buttons.map((button) => (
        <StyledTabBtn
          key={button.tabType}
          onClick={() => onTabSelect(button.tabType)}
          sx={tab === button.tabType ? activeTabStyles : {}}
        >
          {button.label}
        </StyledTabBtn>
      ))}
    </Box>
  );
};

import styled from '@emotion/styled';
import { Box, Button, Paper } from '@mui/material';
import { GeneralTabType } from './types';
const StyledTabBtn = styled(Button)`
  border-radius: 0;

  &:focus {
    background: rgba(0, 0, 0, 0.04);
  }

  &:hover {
  }
`;

const activeTabStyles = {
  borderBottom: '3px solid #177d8e',
  background: 'rgba(0, 0, 0, 0.04)',
};

type TabSelectProps = {
  onTabSelect: (tab: GeneralTabType) => void;
  tab: GeneralTabType;
};

const buttons: { tabType: GeneralTabType; label: string }[] = [
  { tabType: 'licenses', label: 'Licenses' },
  { tabType: 'payments', label: 'Payments' },
  { tabType: 'publishers', label: 'Publishers' },
  { tabType: 'publishing-models', label: 'Publishing models' },
  { tabType: 'mappings', label: 'Mappings' },
];

export const GeneralTabSelect = ({ onTabSelect, tab }: TabSelectProps) => {
  return (
    <Box
      component={Paper}
      sx={{
        marginTop: 12,
      }}
    >
      {buttons.map((button) => (
        <StyledTabBtn
          key={button.tabType}
          onClick={() => onTabSelect(button.tabType)}
          sx={tab === button.tabType ? activeTabStyles : {}}
        >
          {button.label}
        </StyledTabBtn>
      ))}
    </Box>
  );
};

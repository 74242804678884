import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../contexts/AuthContext';
import { useApi } from './useApi';

export const useColumns = () => {
  const { account } = useAuthContext();
  const { makeApiRequest } = useApi();

  const { data: columns = [] } = useQuery({
    queryKey: ['columns', account],
    queryFn: async (): Promise<string[]> => {
      const response = await makeApiRequest('/columns', 'GET');

      if (response && response.columns) {
        return response.columns;
      }
      return [];
    },
    enabled: !!account,
  });

  return {
    columns,
  };
};

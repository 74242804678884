import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton as MuiIconButton,
} from '@mui/material';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }

  &:focus {
    outline: 2px solid #177d8e;
  }
`;

const StyledButton = styled(Button)`
  &:focus {
    outline: 2px solid #177d8e;
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 8px;
    border-radius: 6px;
    max-width: 430px;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 6px;
  gap: 8px;
`;

type DeleteMappingProps = {
  onClick: () => void;
  mappingName: string;
};

export const DeleteMappingDialog = ({
  onClick,
  mappingName,
}: DeleteMappingProps) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    onClick();
    handleClose();
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="Open dialog"
        onClick={handleClickOpen}
        size="small"
      >
        <DeleteIcon />
      </IconButton>
      <StyledDialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this mapping?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The mapping <strong>"{mappingName}"</strong> will be permanently
            deleted and cannot be undone.
          </DialogContentText>
        </DialogContent>
        <StyledDialogActions>
          <StyledButton
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </StyledButton>
          <StyledButton
            onClick={handleDelete}
            color="error"
            variant="contained"
            autoFocus
          >
            Delete
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    </>
  );
};

export default DeleteMappingDialog;

import { Box } from '@mui/material';
import { useLicenses } from '../../../../hooks/api/useLicenses';
import { License } from '../../../../types/Licenses.types';
import { usePayments } from '../../../../hooks/api/usePayments';
import { Payment } from '../../../../types/Payments.types';
import { usePublishers } from '../../../../hooks/api/usePublishers';
import { usePublishingModels } from '../../../../hooks/api/usePublishingModels';
import { PublishingModel } from '../../../../types/PublishingModels.types';
import { Publisher } from '../../../../types/Publishers.types';
import { GeneralTabType } from './types';
import AdminTable from './AdminTable';
import MappingTable from './MappingTable';

interface GeneralTabProps {
  tab: GeneralTabType;
}

const tabConfig: Record<GeneralTabType, { title: string; label: string }> = {
  licenses: { title: 'Licenses', label: 'license' },
  payments: { title: 'Payments', label: 'payment' },
  publishers: { title: 'Publishers', label: 'publisher' },
  'publishing-models': {
    title: 'Publishing Models',
    label: 'publishing model',
  },
  mappings: { title: 'Mappings', label: 'mapping' },
};

export const GeneralTab = ({ tab }: GeneralTabProps) => {
  const { title, label } = tabConfig[tab];
  const { licenses = [], createLicense, isCreatingLicense } = useLicenses();
  const { payments = [], createPayment, isCreatingPayment } = usePayments();
  const {
    publishers = [],
    createPublisher,
    isCreatingPublisher,
  } = usePublishers();
  const {
    publishingModels = [],
    createPublishingModel,
    isCreatingPublishingModel,
  } = usePublishingModels();

  return (
    <Box>
      {tab === 'mappings' && (
        <MappingTable
          label={label}
          licenses={licenses}
          payments={payments}
          publishingModels={publishingModels}
        />
      )}
      {tab === 'licenses' && (
        <AdminTable
          title={title}
          label={label}
          data={licenses.map((license: License) => ({
            id: license._id,
            url: license.url,
            description: license.description,
            icon: license.icon,
            createdAt: license.created_at,
            createdBy: license.created_by,
          }))}
          onAddItem={createLicense}
          isAdding={isCreatingLicense}
        />
      )}
      {tab === 'payments' && (
        <AdminTable
          title={title}
          label={label}
          data={payments.map((payment: Payment) => ({
            id: payment._id,
            description: payment.description,
            icon: payment.icon,
            createdAt: payment.created_at,
            createdBy: payment.created_by,
          }))}
          onAddItem={createPayment}
          isAdding={isCreatingPayment}
        />
      )}
      {tab === 'publishers' && (
        <AdminTable
          title={title}
          label={label}
          data={publishers.map((publisher: Publisher) => ({
            id: publisher._id,
            createdAt: publisher.created_at,
            createdBy: publisher.created_by,
          }))}
          onAddItem={createPublisher}
          isAdding={isCreatingPublisher}
        />
      )}
      {tab === 'publishing-models' && (
        <AdminTable
          title={title}
          label={label}
          data={publishingModels.map((publishingModel: PublishingModel) => ({
            id: publishingModel._id,
            description: publishingModel.description,
            icon: publishingModel.icon,
            createdAt: publishingModel.created_at,
            createdBy: publishingModel.created_by,
          }))}
          onAddItem={createPublishingModel}
          isAdding={isCreatingPublishingModel}
        />
      )}
    </Box>
  );
};

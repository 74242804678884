import { Card, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { GeneralSettings } from './components/general/GeneralSettings';
import { SettingsTabSelect } from './components/SettingsTabSelect';
import { UniversityManagement } from './components/university/UniversityManagement';

export type SuperAdminTabType = 'general' | 'university';

const SuperAdmin = () => {
  const [superAdminTab, setSuperAdminTab] =
    useState<SuperAdminTabType>('general');

  const handleSuperAdminTabSelect = (tab: SuperAdminTabType) => {
    setSuperAdminTab(tab);
  };

  return (
    <Card>
      <Grid container spacing={2} p={16}>
        <Grid item xs={9}>
          <Typography variant="h1" sx={{ fontSize: 20, marginBottom: 16 }}>
            Super Admin
          </Typography>
          <SettingsTabSelect
            tab={superAdminTab}
            onTabSelect={handleSuperAdminTabSelect}
          />
        </Grid>
        {superAdminTab === 'general' && <GeneralSettings />}
        {superAdminTab === 'university' && <UniversityManagement />}
      </Grid>
    </Card>
  );
};

export default SuperAdmin;

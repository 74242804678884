import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from './useApi';

export function useReloadSettings() {
  const queryClient = useQueryClient();
  const { makeApiRequest } = useApi();

  const { mutate: reloadSettings } = useMutation({
    mutationFn: () => makeApiRequest('/settings/reload', 'POST'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['licenses'] });
      queryClient.invalidateQueries({ queryKey: ['publishingModels'] });
      queryClient.invalidateQueries({ queryKey: ['publishers'] });
      queryClient.invalidateQueries({ queryKey: ['payments'] });
      queryClient.invalidateQueries({ queryKey: ['mappings'] });
      queryClient.invalidateQueries({ queryKey: ['columns'] });
    },
  });

  return { reloadSettings };
}

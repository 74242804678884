import { CardContent, Grid, Typography } from '@mui/material';

import { Box } from '@mui/material';

import { Card } from '@mui/material';
import { GeneralTab } from './GeneralTab';
import { useState } from 'react';
import { GeneralTabType } from './types';
import { GeneralTabSelect } from './GeneralTabSelect';

export function GeneralSettings() {
  const [generalTab, setGeneralTab] = useState<GeneralTabType>('licenses');

  const handleGeneralTabSelect = (tab: GeneralTabType) => {
    setGeneralTab(tab);
  };

  return (
    <>
      <Grid item xs={9}>
        <Typography variant="subtitle1" color="secondary">
          Create and manage licenses, payments, publishers, publishing models
          and column mappings.
        </Typography>
      </Grid>
      <Box sx={{ width: '100%' }} p={16}>
        <GeneralTabSelect
          tab={generalTab}
          onTabSelect={handleGeneralTabSelect}
        />
        <Card>
          <CardContent>
            <GeneralTab tab={generalTab} />
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
